<template>
  <div class="box">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoadScroll()"
      >
        <div v-if="dataList.length > 0">
          <div v-for="item in dataList" :key="item.id">
            <router-link :to="{path:'/feedback/lookDetail',query:{id:item.id}}" >
              <IndexListCell :data="item" />
            </router-link>
          </div>
        </div>
        <div class="no-data-box" v-else>
          <img src="@/assets/feedback/noData.png" alt="" class="no-data-img">
          <div class="no-data-text">暂无反馈历史</div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { getFeedbackList } from "@/api/feedback/base.js";
import IndexListCell from "./components/IndexListCell.vue";

export default {
  name: "Feedback",
  components: {
    IndexListCell,
  },
  data() {
    return {
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      refreshing: false
    };
  },
  created() {},
  methods: {
    onLoadScroll() {
      this.getList();
    },
    getList() {
      getFeedbackList({
        pageNum: this.pageIndex,
        pageSize: this.pageSize,
      }).then((res) => {
        let { total, rows } = res;
        console.log(res);
        if (this.refreshing) {
          this.dataList = [];
          this.refreshing = false;
        }
        if (this.pageIndex == 1) {
          this.dataList = rows;
        } else {
          rows.forEach((item) => {
            this.dataList.push(item);
          });
        }
        this.loading = false;

        if (this.dataList.length >= total) {
          this.finished = true;
        }
        this.pageIndex += 1;
      });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.pageIndex = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoadScroll();
    },
  },
};
</script>
<style lang="less" scoped>
.no-data-box{
  width: 100%;
  height: 100vh;
  background-color: #F4F4F4;
  text-align: center;
  overflow: hidden;
  position: fixed;
  .no-data-img{
    width: 2.3rem;
    margin-top: 1.875rem;
  }
  .no-data-text{
    font-size: 0.15rem;
    font-weight: 400;
    color: #666666;
    margin-top: 0.22rem;
  }
}
</style>
