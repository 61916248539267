<template>
  <div class="con pr">
    <div class="classification">
      <span>{{ classificationFunc(data.classification) }}</span>
      <div :class="['state' ,data.state == '已回复' && ('online')]">
        {{data.state}}
      </div>
    </div>
    <div class="flex flex_align_center" v-if="data.classification === 1">
      <img :src="data.image" alt="" />
      <div class="flex_item" style="width: 60%; margin-right: 0.3rem">
        <p class="name txtcut">{{ data.goodsTitle }}</p>
        <p class="type">
          规格：{{ newAttr.length > 0 ? newAttr[0].attrType : "" }}
        </p>
      </div>
      <div class="num">x{{ data.buyNum }}</div>
    </div>
    <div class="content" v-else>{{data.content}}</div>
    <div class="time">提交时间：{{ data.createTime }}</div>
  </div>
</template>
<script>
export default {
  props: {
    data: {},
  },
  computed: {
    newAttr: function () {
      return eval("(" + this.data.skuAttr + ")");
    },
  },
  methods: {
    classificationFunc(str){
      return str = str === 1 ? '商品反馈':str === 2 ? '服务反馈':str === 3 ? '功能反馈':str === 4 ? '我要吐槽': ''
    },
  }
};
</script>
<style lang="less" scoped>
.con {
  margin: 0.1rem;
  padding: 0.1rem;
  background: #fff;
  border-radius: 0.07rem;
  .classification{
    font-size: 0.15rem;
    font-weight: 400;
    color: #333333;
    margin-bottom: 0.1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content{
    font-size: 0.14rem;
    font-weight: 400;
    color: #333333;
  }
  .time {
    font-size: 0.11rem;
    color: #333333;
    margin-top: 0.08rem;
  }
  img {
    width: 0.7rem;
    height: 0.7rem;
    margin-right: 0.1rem;
  }
  .name {
    font-size: 0.13rem;
    color: #333333;
    margin-bottom: 0.03rem;
  }
  .type {
    font-size: 0.1rem;
    color: #666666;
  }
  .num {
    font-size: 0.13rem;
    color: #666666;
  }
  .state {
    border-radius: 0 0.07rem;
    font-size: 0.15rem;
    color: #dc2727;
    text-align: center;
    padding: 0.03rem 0.06rem;
    &.online {
      color: #CCCCCC;
    }
  }
}
</style>
